const { host } = window.location;
let browserEnv = "";
if (host.startsWith("localhost")) {
  browserEnv = "local";
} else if (host.split(".")[0].endsWith("-minside-d")) {
  browserEnv = "dev";
}  else if (host.split('.')[0].endsWith('-minside-t') || host.split('.')[1].endsWith('test')) {
  browserEnv = "test";
}

interface IEnvironment {
  AppName: string;
  MsalClientId: string;
  MsalScope: string;
  MsalInternalUserAuthority: string;
  MsalExternalUserAuthority: string;
  ExternalUserPolicyName: string;
  InternalUserPolicyName: string;
  Current: string;
}

let env: IEnvironment | undefined;

if (browserEnv === "local" || browserEnv === "dev")
  env = {
    AppName: window.spaSettings.appName,
    MsalClientId: window.spaSettings.msalClientId,
    MsalInternalUserAuthority:
      `https://miljodirb2cdev.b2clogin.com/miljodirb2cdev.onmicrosoft.com/${window.spaSettings.internalUserPolicy}`,
    MsalExternalUserAuthority:
      `https://miljodirb2cdev.b2clogin.com/miljodirb2cdev.onmicrosoft.com/${window.spaSettings.externalUserPolicy}`,
    Current: browserEnv,
    MsalScope: window.spaSettings.msalScope,
    ExternalUserPolicyName: window.spaSettings.externalUserPolicy,
    InternalUserPolicyName: window.spaSettings.internalUserPolicy
  };
else if (browserEnv === "test")
  env = {
    AppName: window.spaSettings.appName,
    MsalClientId: window.spaSettings.msalClientId,
    MsalInternalUserAuthority:
      `https://miljodirb2cdev.b2clogin.com/miljodirb2cdev.onmicrosoft.com/${window.spaSettings.internalUserPolicy}`,
    MsalExternalUserAuthority:
      `https://miljodirb2ctest.b2clogin.com/miljodirb2ctest.onmicrosoft.com/${window.spaSettings.externalUserPolicy}`,
    Current: browserEnv,
    MsalScope: window.spaSettings.msalScope,
    ExternalUserPolicyName: window.spaSettings.externalUserPolicy,
    InternalUserPolicyName: window.spaSettings.internalUserPolicy
  };

if (env === undefined) throw new Error("Environment variables not set.");

const Environment = env;

export default Environment;
